export default defineNuxtRouteMiddleware(async (to, from) => {
    try {
        const headers = useRequestHeaders(["cookie"])
        // Important to check only from client, since the sessionToken cookie would not be available
        await $fetch("/api/v1/auth/renew", { 
            method: "GET",
            server: false,
            credentials: "include",
            headers: headers,
        })
        // If no error is thrown but we're going to the login page redirect to admin/events
        if (to.fullPath.includes("login")) {
            return navigateTo("/admin/events")
        }
    } catch (error) {
        if (!to.fullPath.includes("login")) {
            return navigateTo("/admin/login", { redirectCode: 401 })
        }
    }
})